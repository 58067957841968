<template>
    <div class="mb-24">
        <Confirmation :property="{ id: propertyId }"></Confirmation>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Confirmation from '@/components/applicationService/Confirmation.vue';

export default {
    name: 'SubmittedConfirmation',
    components: { Confirmation },
    data() {
        return {
            propertyId: '',
            application: null,
        };
    },

    computed: {
        ...mapGetters({
            getApplication: 'application/getApplication',
        }),
    },

    beforeMount() {
        if (this.getApplication) this.application = this.getApplication;
    },

    mounted() {
        if (this.$route.params) this.propertyId = this.$route.params.propertyId;
    },

    methods: {
        ...mapActions({
            setApplication: 'application/setApplication',
        }),

        savePayment(payload) {
            this.setApplication({
                ...this.getApplication,
                payment: payload,
            });
        },
    },
};
</script>
